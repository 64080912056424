/* eslint-disable max-statements */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as Button } from '@components/ButtonAuth';
import HeaderLogin from '@components/HeaderLogin';
import { StickyScreening } from '@components/Section';
import { OfflineContext } from '@context/OfflineContext';
import useLocalStorage from '@hooks/useLocalStorage';
import useSessionStorage from '@hooks/useSessionStorage';
import SentryTagsInterface from '@interface/sentry/sentryTagsInterface';
import * as Sentry from '@sentry/nextjs';
import {
  getUnusedLicense,
  sendLicense, unfinishedLicense
} from '@services/license';
import {
  Box,
  Container, ErrorInput, InfoInput,
  Loader, StyledBox, TextInput, TextLink as TextLinkDefault
} from '@src';
import { theme } from '@theme';
import checkValidity from '@utils/checkValidity';
import maskInput from '@utils/maskInput';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

export interface LoginProps {
  urlOtp: string;
  urlScore: string;
}

const BoxContent = styled(StyledBox)`
  justify-content: center;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    justify-content: initial;
  }
`;

const ERROR_FORM_LOGIN = 2000;
const TIME_ACTIVE_BUTTON = 1000;

enum Message {
  INACTIVE = `Seu código de verificação expirou.
  Clique em “reenviar” para gerar um novo.`,
  EXPIRED = `Esta senha temporária expirou.
  Clique em "reenviar" para gerar uma nova senha.`,
  RESEND_SUCESS = 'Um novo código de verificação foi enviado para o seu e-mail.'
}

const Score = styled.p`
  width: 100%;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #2C3143;
  margin: 0 0 16px 0;
`;

const TextLink = styled(TextLinkDefault)`
  font-family: 'Barlow';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  color: #2C3143;
`;

const Login = ({ urlOtp, urlScore }: LoginProps) => {
  const { offline } = useContext(OfflineContext);
  const [calculating, setCalculating] = useLocalStorage('calculating', false);
  const [otpToken, setOtpToken] = useSessionStorage('otpToken');
  const [userData, setUserData] = useSessionStorage('userData', {});

  const [email] = useSessionStorage('email');
  const [phone] = useSessionStorage('phone');
  const [formValid, setFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');

  const [totalAnswers] = useSessionStorage('totalAnswers');
  const [totalQuestions] = useSessionStorage('totalQuestions');
  const [uuid] = useSessionStorage('uuid');
  const [activeButton, setActiveButton] = useState(0);
  const [message, setMessage] = useState('');
  const [resendStatus, setResend] = useState(false);
  const [license, setLicense] = useLocalStorage('license');
  const [ownerId] = useLocalStorage('ownerId');
  const [shortLinkOtp] = useLocalStorage('shortLinkOtp');

  const router = useRouter();

  const checkScore = async (email, otpToken)=>{
    try {

      const headers = {
        Authorization: `Bearer ${otpToken}`
      };

      const res: Response = await fetch(`${urlScore}/user/${email}`,
        {
          headers,
          method: 'GET'
        });

      if (res.ok){
        const auxUserData = await res.json();

        if (auxUserData.data){
          setUserData(auxUserData.data);
          return '/dashboard';
        }
      }
    } catch (error){
      const tags: SentryTagsInterface = {
        urlPath: 'login/checkScore',
        email,
        screeningFormUuid: uuid,
        otpToken
      };

      Sentry.captureException(error,
        {
          tags
        });
    }

    return false;
  };

  // eslint-disable-next-line max-statements
  const handlerLogin = async (code: string)=>{
    setLoading(true);

    const header = {
      body: JSON.stringify({
        data:[
          {
            attributes: {
              email,
              code
            }
          }
        ]
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api+json'
      }
    };

    try {
      const res: Response = await fetch( `${urlOtp}/login` , header );
      const otpToken = await res.json();
      if (!res.ok || otpToken.data.length === 0){
        setFormValid(false);
        setLoading(false);
        setCode('');
        setTimeout(()=>{
          setFormValid(true);
        }, ERROR_FORM_LOGIN);
        return;
      }

      const [{ status }] = otpToken.data;

      if (status || status === null) {
        setFormValid(false);
        setLoading(false);
        setMessage(Message[status ===  null ? 'INACTIVE' : status]);
        setCode('');
        setTimeout(()=>{
          setFormValid(true);
        }, ERROR_FORM_LOGIN);
        return;
      }

      const token = otpToken.data[0].attributes.token;
      setOtpToken(token);
      console.log(shortLinkOtp, 'shortLinkOtp');
      if (shortLinkOtp?.email){
        console.log(shortLinkOtp?.email, 'shortLinkOtp?.email');
        const { attributes: unusedLicense } = await getUnusedLicense({
          userId: shortLinkOtp.email,
          ownerId: shortLinkOtp.ownerId,
          token
        });

        if (unusedLicense === undefined){
          localStorage.clear();
          router.push('/usedlicense');
          return;
        }
        console.log(unusedLicense, 'unusedLicense 1');

        setLicense({
          email: unusedLicense.userId,
          licenseId : unusedLicense.id,
          practitionerId : unusedLicense.ownerId
        });
        // setLoading(false);
        router.push('/form');
        return;
      }

      if (!license && ownerId){
        const resLicense = await unfinishedLicense({
          router,
          token,
          userEmail: email,
          ownerEmail: ownerId,
        });

        if (ownerId && resLicense.hasUnfinished === false){
          const responseSendLicense = await sendLicense({
            ownerId,
            userId: email,
            token
          });

          router.push({
            pathname: '/form',
            query: router.query
          });
          return;
        }

        if (resLicense.hasUnfinished){
          const unusedLicense = await getUnusedLicense({
            token,
            userId: email,
            ownerId: ownerId,
          });
          setLicense({
            code,
            email: unusedLicense.attributes.userId,
            licenseId : unusedLicense.attributes.id,
            practitionerId : unusedLicense.attributes.ownerId
          });

          router.push({
            pathname: '/form',
            query: router.query
          });
          return;
        }
      }
      const auxScore = await checkScore(email, token);
      console.log(auxScore, 'auxScore');
      if (auxScore && ((totalQuestions>totalAnswers)
        || !totalQuestions || !totalAnswers) && !license){
        router.push(auxScore);
        return;
      }

      setCalculating(false);
      router.push({
        pathname: '/form',
        query: router.query
      });
    } catch (error) {
      const tags: SentryTagsInterface = {
        urlPath: 'login/handlerLogin',
        email,
        screeningFormUuid: uuid,
        otpToken
      };

      Sentry.captureException(error,
        {
          tags
        });
      setFormValid(false);
      setCode('');
    }
  };

  const resend = async (email,phone)=>{

    setLoading(true);

    setActiveButton(15);

    phone = '55'+phone.replace(/[()\-\s]/g, '');

    const header = {
      body: JSON.stringify({
        data:[
          {
            attributes: {
              email,
              phone
            }
          }
        ]
      }),
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json'
      }
    };

    await fetch(urlOtp, header);

    setFormValid(true);

    setMessage(Message['RESEND_SUCESS']);
    setResend(true);

    setLoading(false);
  };

  const validEmail = checkValidity(code, {
    minLength: 6,
    isNumber: true
  });

  const auxContent = (
    <BoxContent
      styleSheet={{
        alignItems: 'center',
        padding: '32px'
      }}>

      {license ? (
        <StickyScreening>
          <span>SCRENNING</span>
        </StickyScreening>) : (
        <StickyScreening>
          <span>RESULTADOS</span>
        </StickyScreening>)}

      <Score>
        Insira o código OTP
      </Score>

      <TextInput
        styleSheet={{
          margin: '0 0 22px 0'
        }}
        autoFocus
        placeholder={'000000'}
        onChange={(event) =>{
          setCode(maskInput(event.target.value.substr(0,6), 'onlyNumber'));
          setMessage('');
          setResend(false);
        }}
        isValid={validEmail.isValid}
        value={code} />

      {(!formValid || message) && !resendStatus &&
      <ErrorInput
        styleSheet={{
          fontSize: '14px',
          maxWidth: '320px',
          margin: '0 0 40px 0'
        }}>
        {`Código inválido. Clique em "reenviar"
        para gerar um novo código OTP.`}
      </ErrorInput>}

      {(resendStatus && message) &&
      <InfoInput
        styleSheet={{
          fontSize: '14px',
          maxWidth: '320px',
          margin: '22px 0 0 0',
          color:'green'
        }}>
       Código reenviado com sucesso!
      </InfoInput>}

      {formValid && !validEmail.isValid && !message &&
      <InfoInput
        styleSheet={{
          maxWidth: '320px',
          fontSize: '14px',
          margin: '0'
        }}>
          Caso não tenha recebido o sms ou e-mail, verifique
          a caixa de spam e clique em “reenviar”.
      </InfoInput>}

      {(!formValid || !validEmail.isValid) &&
      <Button
        main='primary'
        label={activeButton
          ? `Aguarde ${activeButton}`
          : 'Reenviar'}
        onClick={()=>{
          if (validEmail.isValid || offline || activeButton > 0){
            return;
          }
          setCode('');
          resend(email,phone);
        }} />}

      {formValid && validEmail.isValid &&
      <InfoInput
        styleSheet={{
          maxWidth: '320px',
          margin: '0'
        }}>
        <div>
          Caso não tenha recebido o sms ou e-mail, verifique
          a caixa de spam e clique em “reenviar”.
        </div>
      </InfoInput>}

      {formValid && validEmail.isValid &&
      <Button
        label='Validar'
        onClick={()=>{
          handlerLogin(code);
        }} />}
    </BoxContent>
  );

  return (<Container>
    {!loading && auxContent}
    {loading && (
      <Box
        styleSheet={{
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Loader/>
      </Box>
    )}
  </Container>);
};

export async function getServerSideProps() {

  const urlOtp = process.env.OTP_URL;
  const urlScore = process.env.SCORE_URL;

  return { props: { urlOtp, urlScore } };
}

export default Login;
