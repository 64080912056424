import SentryTagsInterface from '@interface/sentry/sentryTagsInterface';
import * as Sentry from '@sentry/nextjs';
import httpCodes from '@utils/httpCodes';

export const unfinishedLicense = async ({
  userEmail,
  ownerEmail,
  router,
  token }) =>{

  const url = process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL;

  try {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    };
    // eslint-disable-next-line max-len
    const res: Response = await fetch(`${url}owners/${ownerEmail}/users/${userEmail}/unfinished`, header);
    if (res.ok){
      const licenseData = await res.json();
      const license = licenseData.data.attributes;
      return license;
    }
  } catch (error) {
    const tags: SentryTagsInterface = {
      urlPath: `${router}/checkLicense`,
    };

    Sentry.captureException(error,
      {
        tags
      });

    return;
  }

};

export const sendLicense = async ({
  userId,
  ownerId,
  token
}) => {
  try {
    const licenses: any = await fetch(
      `${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            attributes: {
              userId,
              ownerId,
              type: 'SCREENING'
            }
          }
        }),
      }
    );

    if (licenses.status === httpCodes.ok) {
      const { data } = await licenses.json();
      return data;
    }

    throw (licenses);
  } catch (error) {
    const tags: SentryTagsInterface = {
      urlPath: '/login',
    };

    Sentry.captureException(error,
      {
        tags
      });
  }
};

export const getUnusedLicense = async ({
  userId,
  ownerId,
  token
}) => {
  try {
    const licenses: any = await fetch(
      // eslint-disable-next-line max-len
      `${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}owners/${ownerId}/users/${userId}/unused`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (licenses.status === httpCodes.ok) {
      const { data } = await licenses.json();
      return data;
    }

    return licenses;
  } catch (error) {
    const tags: SentryTagsInterface = {
      urlPath: '/login',
    };

    Sentry.captureException(error,
      {
        tags
      });

    return error;
  }
};

export const editLicense = async ({
  ownerId,
  userId,
  userName,
  token,
  licenseId,
  link,
  ownerName
}) => {
  try {

    const licenses: any = await fetch(
      `${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}${licenseId}/user`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            attributes: {
              ownerName,
              userName,
              userId,
              link,
            }
          }
        }),
      }
    );

    if (licenses.status === httpCodes.ok) {
      return;
    }

    throw (licenses);
  } catch (error) {
    const tags: SentryTagsInterface = {
      urlPath: '/home',
      owners: ownerId || '',
    };

    Sentry.captureException(error,
      {
        tags
      });
  }
};
