/* eslint-disable max-len */
import { Button as BoxDefault } from '@src';
import styled from 'styled-components';

const ButtonAuthStyled = styled(BoxDefault)`
  margin-right: 0;
  margin-top: 32px;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 5% auto;
    width: 328px;
    max-width: initial;
    z-index: 10;
  }
`;

const ButtonAuth = (props)=><ButtonAuthStyled {...props}/>;
export default ButtonAuth;
